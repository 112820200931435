import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/vuex";
import { Mutations, Actions } from "@/vuex/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { useAuth } from '@/stores/useAuth'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Home"],
        },
      },
      {
        path: "/users",
        name: "Users",
        component: () => import("@/views/user/Users.vue"),
        meta: {
          pageTitle: "User",
          breadcrumbs: ["Users"],
        },
      },
      {
        path: "/schools",
        name: "Schools",
        component: () => import("@/views/schools/Schools.vue"),
        meta: {
          pageTitle: "School",
          breadcrumbs: ["Schools"],
        },
      },
      {
        path: "/students",
        name: "Students",
        component: () => import("@/views/student/Student.vue"),
        meta: {
          pageTitle: "Student",
          breadcrumbs: ["Students"],
        },
      },
      {
        path: "/questions",
        name: "Questions",
        component: () => import("@/views/questions/Questions.vue"),
        meta: {
          pageTitle: "Questions",
          breadcrumbs: ["Questions"],
        },
      },
      {
        path: "/survey",
        name: "Survey",
        component: () => import("@/views/survey/Survey.vue"),
        meta: {
          pageTitle: "Survey",
          breadcrumbs: ["Survey"],
        },
      },
      {
        path: "/school/details/:id",
        name: "School Details",
        component: () => import("@/views/schools/SchoolDetails.vue"),
        props: (route) => ({
          id: route.params.id
        }),
        meta: {
          pageTitle: "School",
          breadcrumbs: ["School", 'Details'],
        },
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/LayoutBuilder.vue"),
        meta: {
          pageTitle: "Layout Builder",
          breadcrumbs: ["Layout"],
        },
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        meta: {
          breadcrumbs: ["Pages", "Profile"],
        },
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
            meta: {
              pageTitle: "Overview",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/auth/Auth.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({ 
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async(to, from, next) => {
  const auth = useAuth()
  
  document.title = `${to.meta.pageTitle}`;

  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (to.name !== 'sign-in') {
      await auth.me()

      if (!auth.isLoggedIn) {
        next({ name: "sign-in" });
      } else 
        next()
  } else {
    next();
  }

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
