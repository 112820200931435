import { createApp } from "vue"
import App from "./App.vue"

import router from "./router"
import store from "./vuex"
import ElementPlus from "element-plus"
import i18n from "@/core/plugins/i18n"
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice'

import ApiService from "@/core/services/ApiService"
import { initApexCharts } from "@/core/plugins/apexcharts"
import { initInlineSvg } from "@/core/plugins/inline-svg"
import { initVeeValidate } from "@/core/plugins/vee-validate"
import { createPinia } from 'pinia'

import "@/core/plugins/prismjs";
import dayjs from 'dayjs'
const pinia = createPinia()

const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(PrimeVue)
app.use(pinia)
app.use(ConfirmationService)
app.use(ToastService)
ApiService.init(app)
initApexCharts(app)
initInlineSvg(app)
initVeeValidate()
app.provide('dayJS', dayjs)
app.use(i18n)

app.mount("#app")

import '@/assets/scss/styles.scss'